import React from 'react'
import { ScreenContainer } from '../common/containers/ScreenContainer'
import * as styles from './styles.module.scss'
import contactIcon from '../../assets/contact.svg'
import mailIcon from '../../assets/icons/mail.svg'

export const Contact = () => {
  return (
    <section id="contact">
      <ScreenContainer>
        <div className={styles.contactWrapper}>
          <img src={contactIcon} alt="contact" />
          <div>
            <h2>Har du stadig spørgsmål?</h2>
            <a href="mailto:info@multiregnskab.dk">
              <button>
                <img src={mailIcon} alt="mail" />
              Kontakt
            </button>
            </a>
            <p>Tryk 'Kontakt' for at sende en mail til os.Så ser vi på spørgsmålet.</p>
          </div>
        </div>
      </ScreenContainer>
    </section>
  )
}

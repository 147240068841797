import { Link } from 'gatsby'
import React from 'react'
import logo from '../../../../assets/logo.svg';
import * as styles from './styles.module.scss'

export const Logo = ({ style, title = "Mit Holding" }) => {
  return (
    <Link to="/" className={styles.mainLogo} style={style}>
      <img className={styles.logo} src={logo} alt="logo" />
      <span>{title}</span>
    </Link>
  )
}

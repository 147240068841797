import React, { useEffect, useState } from 'react'
import * as styles from './styles.module.scss'
import { Logo } from '../Logo';
import clsx from 'clsx';

export const Header = ({ noSearch = false }) => {
  const [offset, setOffset] = useState(false);
  const url = typeof window !== 'undefined' ? window.location.pathname : '';
  useEffect(() => {
    if (typeof window !== `undefined`) {
      window.onscroll = () => {
        setOffset(window.pageYOffset > 84)
      }
    }
  }, []);

  return (
    <header className={clsx(styles.header, { [styles.bgfull]: noSearch || offset })}>
      <div className={styles.wrapper}>
        <div>
          <Logo />
        </div>
        <div className={styles.right}>
          {/* <Link to="/">Find dit holding</Link> */}
          <a href={`${url}#contact`}>Kontakt</a>
        </div>
      </div>
    </header>
  )
}



import clsx from 'clsx'
import BackgroundImage from 'gatsby-background-image'
import React from 'react'
import { Helmet } from 'react-helmet'
import { Contact } from '../Contact'
import { PhotoCards } from '../PhotoCards'
import { Footer } from './common/Footer'
import { Header } from './common/Header'
import { SearchSection } from './common/SearchSection'
import * as styles from './styles.module.scss'

export const Layout = ({ children, bgImage, noSearch = false, articles, title = "Multi-Regnskab" }) => {

  if (typeof window !== "undefined") {
    // eslint-disable-next-line global-require
    require("smooth-scroll")('a[href*="#"]')
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
        <meta name="description" content="Holding company" />
      </Helmet>
      <main>
        {!noSearch ?
          <BackgroundImage
            Tag="section"
            className={styles.headerSection}
            fluid={bgImage.childImageSharp.fluid}
          >
            <div className={clsx(styles.overlay, styles.primary)} />
            <div className={clsx(styles.overlay, styles.secondary)} />
            <Header />
            <SearchSection articles={articles} />
          </BackgroundImage>
          :
          <Header noSearch />}
        {children}
        <PhotoCards />
        <Contact />
        <Footer />
      </main>
    </>
  )
}

import React, { useEffect, useState } from 'react'
import * as styles from './styles.module.scss'
import searchIcon from '../../../../assets/icons/search.svg'
import clearIcon from '../../../../assets/icons/cancel.svg'
import { Link } from 'gatsby'

export const SearchSection = ({ articles }) => {
  const [showArticles, setShowArticles] = useState([]);
  const [search, setSearch] = useState('');
  useEffect(() => {
    if (search.length) {
      let tempArr = []
      const searchStr = search.toLowerCase();
      articles.forEach(articleGroup => {
        if (articleGroup.groupTitle.toLowerCase().includes(searchStr)) {
          tempArr = tempArr.concat(articleGroup.guideDocs);
        } else {
          articleGroup.guideDocs.forEach(article => {
            if (article.guideTitle.toLowerCase().includes(searchStr) ||
              article.contents.toLowerCase().includes(searchStr)) {
              tempArr.push(article);
            }
          })
        }
        setShowArticles(tempArr);
      });
    } else {
      setShowArticles([]);
    }

    // return () => clearTimeout(timer);
    // eslint-disable-next-line
  }, [search]);

  return (
    <div className={styles.main}>
      <h6>
        Overvejer du et holdingselskab eller har du allerede et?
        <br />Så er dette site til dig.
      </h6>
      <div className={styles.inputWrapper}>
        <button className={styles.searchIcon}>
          <img src={searchIcon} alt="search" height={24} />
        </button>
        <input
          type="text"
          placeholder="Søg efter artikel"
          value={search}
          onChange={e => setSearch(e.target.value)}
        />
        {!!search.length &&
          <button className={styles.clearIcon} onClick={() => setSearch('')}>
            <img src={clearIcon} alt="clear" height={24} />
          </button>
        }
      </div>
      {!!showArticles.length &&
        <div className={styles.articleList}>
          {showArticles.map(guide => (
            <Link to={`/da/guide/${guide.uri}`} key={guide.uri} >
              {guide.guideTitle}
            </Link>
          ))}
        </div>
      }
    </div>
  )
}

import React from 'react'
import { StaticImage } from "gatsby-plugin-image";
import Video from '../common/Video'
import * as styles from './styles.module.scss'
import noteIcon from '../../assets/icons/note.svg'
import gearIcon from '../../assets/icons/gear.svg'
import clsx from 'clsx'

export const PhotoCards = () => {
  return (
    <section className={styles.main}>
      <div className={styles.photoCard}>
        <div className={styles.text}>
          <img src={noteIcon} alt="note" />
          <p>Multi-Regnskab er Danmarks eneste regnskabsprogram med særlig
            funktionalitet til at bogføre og lave årsrapport for et holdingselskab</p>
        </div>
        <StaticImage
          src="../../assets/cards/girl.png"
          alt="Girl"
          placeholder="blurred"
        />
      </div>
      <div className={styles.photoCard}>
        <StaticImage
          src="../../assets/cards/comp.png"
          alt="Comp"
          placeholder="blurred"
        />
        <div className={clsx(styles.text, styles.flip)}>
          <img src={gearIcon} alt="settings" />
          <p>Det koster kun 500 kr om året (excl. moms) at benytte Multi-Regnskab for et simpelt holding</p>
        </div>
      </div>
      <div className={styles.videoSection}>
        <div>
          <h6>Se hvordan man bruger Multi-Regnskab i et simpelt holdingselskab</h6>
          <Video
            videoSrcURL="https://www.youtube.com/embed/27s-e61O9BQ"
            videoTitle="Bogføring og årsrapport i et simpelt holdingselskab"
          />
        </div>
      </div>
    </section>
  );
}

import React from 'react'
import * as styles from './styles.module.scss'

export const ScreenContainer = ({ children }) => {
  return (
    <div className={styles.wrapper}>
      {children}
    </div>
  )
}

import React from 'react'
import { Logo } from '../Logo'
import * as styles from './styles.module.scss'

export const Footer = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.main}>
        <div className={styles.links}>
          <div className={styles.left}>
            <Logo title="Multi-Regnskab ApS" style={{ marginLeft: 0 }} />
            <p>
              The Camp<br />
              Klausdalsbrovej 601<br />
              2750 Ballerup<br />
              Denmark<br />
              <br /><br />
              CVR 39 23 93 53
            </p>
          </div>
          <div className={styles.right}>
            <h6>Kontakt</h6>
            <a href="mailto:info@multiregnskab.dk">info@multiregnskab.dk</a>
          </div>
          <div />
        </div>
        <p className={styles.disclaimer}> Al tekst, alle billeder og videoer på denne side, er
        beskyttet af loven om ophavsret. Det er ikke tilladt at "låne" eller<br /> kopiere hele
        eller dele af teksten uden forudgående skriftlig tilladelse.</p>
      </div>
    </div>
  )
}
